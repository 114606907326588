// Exemple de contexte
import React, { createContext, useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ApiService from '../services/api';

const ProjectContext = createContext();

const ProjectContextProvider = ({ children }) => {
    const { slug } = useParams();
    const { id } = useParams();
    const [project, setProject] = useState(null);
    const [projectSlug, setProjectSlug] = useState(slug);
    const [graph, setGraph] = useState(null);
    const [graphID, setGraphID] = useState(id);
    const [loadingProject, setLoadingProject] = useState(false);
    const [loadingGraph, setLoadingGraph] = useState(false);
    const [colors, setColors] = useState(null);

    useEffect(() => {
        // console.log('ProjectContextProvider project', projectSlug);
        if (projectSlug) {
            switch (projectSlug) {
                case 'capeb':
                    setColors({
                        //  primary: '#EF4734',
                        //   secondary: '#FBB771',
                        //   ternary: '#D5EBD3',
                        //   quaternary: '#50BD82',
                        primary: '#c6362d',
                        secondary: '#dc9124',
                        ternary: '#54287f',
                        quaternary: '#5899d4',
                        plus: '#5ea895',
                        minus: '#c6362d',
                        mapColors: {
                            stroke: '#eee',
                            fill: '#b0b0b0',
                        },
                    });
                    break;
            }

            setLoadingProject(true);
            ApiService.getProjetBySlug(projectSlug)
                .then((data) => {
                    setProject(data);
                    setLoadingProject(false);
                })
                .catch((e) => {
                    console.log('Error load project');
                    setLoadingProject(false);
                });
        }
    }, [projectSlug]); // Utilisation d'un tableau de dépendances vide pour effectuer le fetch une seule fois

    useEffect(() => {
        console.log('ProjectContextProvider graph', graphID);
        if (graphID) {
            setLoadingGraph(true);
            ApiService.getGraph(graphID)
                .then((data) => {
                    setGraph(data);
                    setLoadingGraph(false);
                })
                .catch((e) => {
                    console.log('Error load graph');
                    setLoadingGraph(false);
                });
        } else {
            setLoadingGraph(false);
        }
    }, [graphID]);

    return (
        <ProjectContext.Provider
            value={{ project, setProjectSlug, graph, setGraphID, loading: loadingProject || loadingGraph, colors }}
        >
            {children}
        </ProjectContext.Provider>
    );
};

export const useProjectContext = () => {
    return useContext(ProjectContext);
};

export default ProjectContextProvider;
