import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import './assets/styles/App.scss';
import ProjectContextProvider from './context/ProjectContext';

import Sommaire from './pages/Sommaire';
import Graph from './pages/Graph';
import NotFound from './pages/NotFound';

export default class App extends React.Component {

  render() {
    return (
        <div className="ra-app">
            <main className="ra-main">
                <ProjectContextProvider>
                    <Router>
                        <Routes>
                            <Route path="/projet/:slug/:id" element={<Graph />} />
                            <Route path="/projet/:slug" element={<Sommaire />} />
                            <Route path="*" element={<NotFound />} />
                        </Routes>
                    </Router>
                </ProjectContextProvider>
            </main>
        </div>
    );
  }
}
