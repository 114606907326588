/* global URLSearchParams */
import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';

import Navbar from '../../components/capeb/Navbar';
import PageTitle from '../../components/capeb/PageTitle';
import Filters from '../../components/capeb/Filters';
import Source from '../../components/capeb/Source';
import Loader from '../../components/Loader';

import MapAndHistogram from '../../components/capeb/MapAndHistogram';
import QuestionAndHistogram from '../../components/capeb/QuestionAndHistogram';
import TableauSynthetique from '../../components/capeb/TableauSynthetique';

import ApiService from '../../services/api';

import '../../assets/styles/pages/capeb/capeb-graph.scss';

const Graph = ({ project, graph }) => {
    const [total, setTotal] = useState(null);
    const [sectors, setSectors] = useState([]);
    const [limitations, setLimitations] = useState([]);
    const [selectedSectors, setSelectedSectors] = useState({});
    const [selectedLimitation, setSelectedLimitation] = useState('');
    const [loading, setLoading] = useState(true);

    const location = useLocation();
    const navigate = useNavigate();
    let queryParams = new URLSearchParams(location.search);

    // ------------------------------------------
    // Load graph sectors and limitations options
    // ------------------------------------------
    const loadSectorsAndLimitation = () => {
        return Promise.all([ApiService.getGraphSectors(graph.id), ApiService.getLimitations(graph.id)]);
    };

    // ------------------------------------------
    // Set default sector and limitation value from url
    // ------------------------------------------
    const loadSelectedUrlValue = (sectors, limitations) => {
        const result = {
            limitation: queryParams.get('dep') || '',
            sectors: {},
        };
        setSelectedLimitation(result.limitation);

        switch (graph.type) {
            case 'capeb-tableau-de-bord-synthetique-reg':
            case 'capeb-tableau-de-bord-synthetique-dep':
                result.sectors = {
                    sector: queryParams.get('sector') || '1',
                    size: queryParams.get('size') || '',
                };
                break;
            case 'capeb-mise-application-charte-reseau-dep':
            case 'capeb-mise-application-charte-reseau-reg':
                result.sectors = {
                    sector: queryParams.get('sector') || '',
                    parent: queryParams.get('parent') || '',
                    question: queryParams.get('question') || '',
                };
                // if (sectors && !result.sectors.sector) {
                //   const thematique = sectors[0] || {};
                //   result.sectors.parent = thematique.code;
                // }
                break;
            case 'capeb-taux-mise-oeuvre-global-dep':
            case 'capeb-taux-mise-oeuvre-global-reg':
                result.sectors = {
                    sector: queryParams.get('sector') || '',
                    parent: queryParams.get('parent') || '',
                };
                // if (sectors && !result.sectors.sector) {
                //   const thematique = sectors[0] || {};
                //   result.sectors.parent = thematique.code;
                // }
                break;
            default:
                result.sectors = {
                    sector: queryParams.get('sector') || '',
                };
                if (sectors && !result.sectors.sector) {
                    result.sectors.sector = (sectors[0] || {}).code;
                }
                break;
        }
        setSelectedSectors(result.sectors);

        return result;
    };

    const onLoad = () => {
        setLoading(true);
        loadSectorsAndLimitation()
            .then(([s, l]) => {
                setLoading(false);
                setSectors(s);
                setLimitations(l);
                let selected = loadSelectedUrlValue(s, l);
                loadTotal(selected.sectors, selected.limitation, false);
            })
            .catch((e) => {
                console.log('error load all');
                setLoading(false);
            });
    };

    // ------------------------------------------
    // On graph props change
    // - Load graph sectors and limitations
    // - Set default selected sector and limitation
    // - Load total
    // ------------------------------------------
    useEffect(() => {
        onLoad();
    }, [graph]);

    // ------------------------------------------
    // On back button from browser
    // - Load graph sectors and limitations
    // - Set default selected sector and limitation
    // - Load total
    // ------------------------------------------
    useEffect(() => {
        const handlePopstate = () => {
            // Récupérez la chaîne de requête actuelle
            const currentQueryString = window.location.search;

            // Utilisez URLSearchParams pour accéder aux paramètres de requête
            queryParams = new URLSearchParams(currentQueryString);

            onLoad();
        };

        window.addEventListener('popstate', handlePopstate);
        return () => {
            window.removeEventListener('popstate', handlePopstate);
        };
    }, [location.pathname]);

    // ------------------------------------------
    // Handle sectors filter change event
    // ------------------------------------------
    const handleSectorFilterChange = (filterID, value) => {
        const newSelectedSectors = { ...selectedSectors };
        newSelectedSectors[filterID] = value;

        switch (graph.type) {
            case 'capeb-mise-application-charte-reseau-dep':
            case 'capeb-mise-application-charte-reseau-reg':
                switch (filterID) {
                    case 'parent':
                        newSelectedSectors.sector = '';
                    case 'sector':
                        newSelectedSectors.question = '';
                    default:
                        loadTotal(newSelectedSectors, selectedLimitation);
                        break;
                }
                break;
            case 'capeb-taux-mise-oeuvre-global-dep':
            case 'capeb-taux-mise-oeuvre-global-reg':
                switch (filterID) {
                    case 'parent':
                        newSelectedSectors.sector = '';
                    default:
                        loadTotal(newSelectedSectors, selectedLimitation);
                        break;
                }
                break;
            default:
                loadTotal(newSelectedSectors, selectedLimitation);
                break;
        }
        setSelectedSectors(newSelectedSectors);
    };

    // ------------------------------------------
    // Handle limitation filter change event
    // ------------------------------------------
    const handleLimitationFilterChange = (value) => {
        setSelectedLimitation(value);
        loadTotal(selectedSectors, value);
    };

    // ------------------------------------------
    // Load graph total
    // - No need to update url if it is the load on first render
    // ------------------------------------------
    const loadTotal = (selectedSectors, selectedLimitation, updateUrl = true) => {
        const newSelectedSectors = { ...selectedSectors };
        setLoading(true);
        if (updateUrl) {
            // Modifiez ou ajoutez des paramètres selon vos besoins
            // const queryParams = new URLSearchParams(location.search);
            switch (graph.type) {
                case 'capeb-tableau-de-bord-synthetique-dep':
                case 'capeb-tableau-de-bord-synthetique-reg':
                case 'capeb-montant-cotisation-fixe-reg':
                case 'capeb-montant-cotisation-fixe-dep':
                case 'capeb-cotisations-syndicales-totales-dep': 
                case 'resultat-net-dep':
                case 'capeb-montant-cotisation-variable-reg':
                case 'capeb-montant-cotisation-variable-dep':
                    break;
                default:
                    queryParams.set('dep', selectedLimitation);
                    break;
            }

            Object.keys(newSelectedSectors).map((key) => {
                queryParams.set(key, newSelectedSectors[key]);
            });

            // Générez la nouvelle chaîne de requête
            const newQueryString = queryParams.toString();

            // Modifiez l'URL en utilisant history.push (React Router v5) ou history.replace (React Router v6)
            // Utilisez history.push si vous voulez ajouter une nouvelle entrée à l'historique
            // Utilisez history.replace si vous voulez remplacer l'entrée actuelle dans l'historique
            navigate({
                pathname: location.pathname,
                search: newQueryString ? `?${newQueryString}` : '',
            });
        }

        const sectorValue = newSelectedSectors.sector || '';
        const parentValue = newSelectedSectors.parent || '';
        delete newSelectedSectors.parent;
        delete newSelectedSectors.sector;

        ApiService.getGraphData(graph.id, selectedLimitation, sectorValue, parentValue, newSelectedSectors)
            .then((data) => {
                setTotal(data);
                setLoading(false);
            })
            .catch(() => {
                setTotal(null);
                setLoading(false);
            });
    };

    // ------------------------------------------
    // Display graph Content
    // ------------------------------------------
    const renderGraphContent = () => {
        switch (graph.type) {
            case 'capeb-tableau-de-bord-synthetique-reg':
            case 'capeb-tableau-de-bord-synthetique-dep':
                return <TableauSynthetique graph={graph} total={total} />;
            case 'capeb-mise-application-charte-reseau-dep':
            case 'capeb-mise-application-charte-reseau-reg':
                return <QuestionAndHistogram graph={graph} total={total} />;
            case 'capeb-taux-mise-oeuvre-global-dep':
            case 'capeb-taux-mise-oeuvre-global-reg':
            case 'capeb-nombre-adherent-et-evolution-dep':
            case 'capeb-taux-syndicalisation-dep':
            case 'charge-personnelle-charge-exploitation-dep':
            case 'capeb-taux-depart-dep':
            case 'capeb-taux-nouveaux-adherents-dep':
            case 'capeb-pourcentage-adherent-travaillant-dep':
            case 'capeb-pourcentage-adherents-employeurs-dep':
            case 'capeb-pourcentage-adherents-employeurs-nombre-adherents-dep':
            case 'capeb-nombre-collaborateur-etp-dep':
            case 'capeb-nombre-collaborateur-etp-reg':
            case 'capeb-montant-cotisation-fixe-dep':
            case 'capeb-cotisations-syndicales-totales-dep':
            case 'capeb-montant-cotisations-syndicales-totales-produits-exploitation-dep':
            case 'resultat-net-dep':
            case 'capeb-montant-cotisation-fixe-reg':
            case 'capeb-montant-cotisation-variable-dep':
            case 'capeb-montant-cotisation-variable-reg':
                return <MapAndHistogram graph={graph} total={total} />;
        }
        return '';
    };

    // ------------------------------------------
    // Render
    // ------------------------------------------
    return (
        <div className={`capeb-graph ${graph ? 'capeb-graph--' + graph.type + ' capeb-graph--' + graph.id : ''}`}>
            <div className="capeb-graph__inner">
                {loading && <Loader />}
                <Navbar project={project} />
                <PageTitle>{graph.name}</PageTitle>
                <Filters
                    graph={graph}
                    sectors={sectors}
                    selectedSectors={selectedSectors}
                    handleSectorChange={handleSectorFilterChange}
                    limitations={limitations}
                    selectedLimitation={selectedLimitation}
                    handleLimitationChange={handleLimitationFilterChange}
                />

                {renderGraphContent()}

                <Container>
                    <Source graph={graph} />
                </Container>
            </div>
        </div>
    );
};

export default Graph;
